export default {
  header: 'Clients',
  deleteConfirmationTitle: 'Delete client',
  deleteConfirmationText: 'Do you really want to delete client: {name}?',
  restoreConfirmationTitle: 'Restore client',
  restoreConfirmationText: 'Do you want to restore client: {name}?',
  changePasswordConfirmationTitle: 'Change billing ftp password',
  changePasswordConfirmationText: 'Do you really want to change Billing FTP password?',
  changeOdMapConfirmationTitle: 'Change OD Map Credentials',
  changeOdMapConfirmationText: 'Do you really want to change OD Map Credentials?',
  changeZollCredsConfirmationTitle: 'Change ZOLL Cloud credentials',
  changeZollCredsConfirmationText: 'Do you really want to change ZOLL Cloud Credentials?',
  filters: {
    search: 'Search',
    type: 'Type',
    status: 'Status',
    statuses: {
      all: 'All',
      active: 'Active',
      deleted: 'Deleted',
    },
    mapToType: 'Map To Type',
  },
  tableHeaders: {
    id: 'Id',
    name: 'Name',
    type: 'Type',
    domain: 'Domain',
    mapToType: 'Map To Type',
    template: 'Template',
    countDevices: 'Devices',
    lastUsed: 'Last Used',
    comment: 'Comment',
  },
  editPopup: {
    headerEdit: 'Edit Client',
    headerNew: 'Add Client',
    name: 'Client Name',
    clientType: 'Client Type',
    mapToType: 'Map To Type',
    faxEnabled: 'Faxing Enabled',
    blsAllowed: 'Allow BLS Locking between devices',
    ceEnabled: 'CE Enabled',
    url: 'URL',
    passwordExpiresAt: 'Go Live Date',
    template: 'Template',
    nemsisMapping: 'NEMSIS Mapping',
    uploadAvatar: 'Upload Logo',
    comments: 'Comments',
    adminEmail: 'Client Admin Email',
    adminPassword: 'Admin Password',
    retypePassword: 'Retype Password',
    billingFtpCredentials: 'Billing SFTP credentials:',
    billingServer: 'Billing SFTP Server',
    billingUser: 'Billing SFTP User',
    billingPassword: 'Billing SFTP Password',
    emptyPassword: 'Leave blank for no FTP user',
    impersonate: 'Impersonate',
    odMapLogin: 'Od Map API Login',
    odMapPassword: 'Od Map API Password',
    odMapCredentials: 'OD Map API credentials:',
    zollCloudCredentials: 'ZOLL Cloud credentials:',
    lifeNetCredentials: 'Lifenet Account credentials:',
    lifeNetAccountId: 'Lifenet Account ID',
    zollCloudDak: 'ZOLL Cloud DAK',
    zollCloudPassword: 'ZOLL Cloud Password',
    address: 'Address',
    city: 'City',
    state: 'State',
    zip: 'Zip',
  },
};
