<template>
  <v-dialog
      v-model="dialog"
      max-width="1100px"
  >
    <v-card v-if="loading">
      <v-card-text class="mt-0 pt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span>View CE Entry</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col
            sm="3"
            :class="[ $style['row-title'] ]"
          >
            Date:
          </v-col>
          <v-col sm="9">{{ entry.date | dateFormat }}</v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            sm="3"
            :class="[ $style['row-title'] ]"
          >
            Provider:
          </v-col>
          <v-col sm="9">{{ entry.provider }}</v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
              sm="3"
              :class="[ $style['row-title'] ]"
          >
            Course Title:
          </v-col>
          <v-col sm="9">
            {{ entry.courseTitle }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
              sm="3"
              :class="[ $style['row-title'] ]"
          >
            Hours:
          </v-col>
          <v-col sm="9">{{ convertNumberToTime(entry.hours) }}</v-col>
        </v-row>
        <v-row
            v-if="canSeeInstructorBasedType && entry.certificateIsAvailable"
            class="mt-0"
        >
          <v-col
              sm="3"
              :class="[ $style['row-title'] ]"
          >
            Instructor Based Type:
          </v-col>
          <v-col sm="9">{{ mapInstructorBasedType(entry.instructorBasedType) }}</v-col>
        </v-row>
        <v-row
          v-if="showAttendees"
          class="mt-0"
        >
          <v-col
              sm="3"
              :class="[ $style['row-title'], 'mt-1']"
          >
            Attendees:
          </v-col>
          <v-col sm="9">
            <v-chip
                v-for="attendee in entry.attendees"
                :key="'attendee_' + attendee.id"
                class="ml-1 mr-1 mb-1"
                color="primary"
                label
                outlined
            >
              <v-icon left>
                mdi-account-circle-outline
              </v-icon>
              <strong>{{ attendee.name }}</strong>

              <span v-if="attendee.licenseTypeId">&nbsp;({{ attendee | licenseType }})</span>
            </v-chip>
          </v-col>
        </v-row>
        <v-row
            v-if="entry.skills && entry.skills.length"
            class="mt-0"
        >
          <v-col
              sm="3"
              :class="[ $style['row-title'], 'mt-1' ]"
          >
            Skills:
          </v-col>
          <v-col sm="9">
            <v-chip
                v-for="item in entry.skills"
                :key="'skill_' + item.id"
                class="ml-1 mr-1 mb-1"
                color="primary"
                label
                outlined
            >
              <strong>{{ item.skill }}</strong>
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
              sm="3"
              :class="[ $style['row-title'] ]"
          >
            Attachments:
          </v-col>
          <v-col sm="9">
            <div
                v-for="file in entry.files"
                v-bind:key="file.id"
            >
              <a
                  :href="file.url"
                  target="_blank"
              >{{ file.name }}</a><br />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <template v-if="showAdminTools">
          <div
              v-if="!entry.approved && !entry.rejected"
              class="float-right"
          >
            <v-btn
                small
                class="mr-2"
                @click="$emit('reject', entry); dialog = false;"
            >
              <v-icon
                  small
                  class="mr-1"
                  color="red"
              >
                mdi-close-octagon
              </v-icon>
              Reject
            </v-btn>
            <v-btn
                small
                @click="$emit('approve', entry); dialog = false;"
            >
              <v-icon
                  small
                  class="mr-1"
                  color="green"
              >
                mdi-check-decagram
              </v-icon>
              Approve
            </v-btn>
          </div>
        </template>
        <v-spacer />
        <v-btn
            text
            color="blue darken-1"
            @click="dialog = false"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import ceHours from '@/api/ceHours';
import { convertNumberToTime } from '@/utils/convertNumberToTime';

export default {
  name: 'ViewEntryPopup',
  filters: {
    licenseType(item) {
      const map = {
        9925005: 'EMT',
      };
      if (map[item.licenseTypeId]) {
        return map[item.licenseTypeId];
      }
      return item.licenseType;
    },
  },
  props: {
    showAttendees: {
      type: Boolean,
      default: false,
    },
    showAdminTools: {
      default: true,
      type: Boolean,
    },
    canSeeInstructorBasedType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      convertNumberToTime,
      loading: false,
      dialog: false,
      entry: {},
      datePicker: false,
      attendees: [],
    };
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    mapInstructorBasedType(type) {
      const map = {
        instructor: 'Instructor Based',
        'non-instructor': 'Non-Instructor Based',
      };
      return map[type];
    },

    /**
     * Show dialog.
     */
    async show(entryId) {
      this.loading = true;
      this.dialog = true;
      let entry = {};
      entry = await ceHours.getEntry(entryId);
      entry.attendees = [];
      if (this.showAttendees) {
        entry.attendees = await ceHours.getAttendees(entryId);
      }
      this.entry = entry;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" module>
.row-title {
  font-weight: bold;
}
</style>
