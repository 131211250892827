<template>
  <v-dialog
      v-model="dialog"
      max-width="1100px"
      persistent
      class="edit-entry-popup"
  >
    <v-card v-if="loading">
      <v-card-text class="mt-0 pt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span>{{ isNew ? 'Add CE Entry' : 'Edit CE Entry' }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
          v-if="dialog"
          class="pb-0"
      >
        <v-alert
            v-if="showExternalCeEntryAlert"
            icon="mdi-alert-outline"
            prominent
            text
            type="info"
        >
          Please only use this screen if you have obtain CE hours outside of your agency.
          If you have taken CE through your agency,
          your educator will perform the entry on your behalf.
        </v-alert>

        <v-alert
            v-if="errorAlert"
            type="error"
            outlined
            transition="scale-transition"
            class="mt-2"
        >
          {{ errorMessage }}
        </v-alert>

        <v-layout wrap>
          <v-flex xs5>
            <v-menu
                ref="datePicker"
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="dateFormatted"
                    v-validate="{ required: true}"
                    label="Date"
                    readonly
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    name="date"
                    :error-messages="errors.collect('date')"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="entry.date"
                  no-title
                  :allowed-dates="dateIsAllowed"
                  @input="datePicker = false"
              />
            </v-menu>
          </v-flex>
          <v-flex
            xs6
            offset-xs1
          >
            <v-combobox
                v-model="entry.provider"
                v-validate="{ required: true, regex: providerRegExp }"
                :items="providersFormatted"
                item-text="name"
                item-value="numberId"
                :return-object="false"
                label="Provider"
                name="provider"
                maxlength="255"
                :error-messages="errors.collect('provider')"
                @keyup="providerKeyUpHandler"
                @change="providerChangedHandler"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="entry.courseTitle"
              v-validate="{ required: true}"
              label="Course Title"
              name="courseTitle"
              maxlength="255"
              :error-messages="errors.collect('courseTitle')"
            />
          </v-flex>
          <v-flex xs12>
            <v-menu
                ref="menu"
                v-model="timePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="time"
                    v-validate="{ required: true}"
                    label="Hours"
                    required
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    name="hours"
                    :error-messages="errors.collect('hours')"
                    v-on="on"
                ></v-text-field>
              </template>
              <custom-time-picker
                  v-model="time"
                  :hide-btn-now="true"
                  title-name="Hours"
                  @close="timePickerMenu = false"
              />
            </v-menu>
          </v-flex>
          <v-flex
              v-if="showInstructorBasedType"
              xs12
          >
            <v-select
                v-model="entry.instructorBasedType"
                label="Instructor Based Type"
                :items="[
                    {text: 'Instructor Based', value: 'instructor'},
                    {text: 'Non-Instructor Based', value: 'non-instructor'},
                ]"
                :return-object="false"
            />
          </v-flex>
          <v-flex
            v-if="showAttendees"
            xs12
          >
            <v-autocomplete
                v-model="entry.userIds"
                v-validate="{ required: showAttendees}"
                :items="attendees"
                chips
                label="Attendees"
                item-text="name"
                item-value="id"
                multiple
                name="attendees"
                :error-messages="errors.collect('attendees')"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeUser(data.item)"
                >
                  <v-icon left>
                    mdi-account-circle-outline
                  </v-icon>

                  {{ data.item.name }} ({{ data.item | licenseType }})
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-action>
                  <v-checkbox v-model="attendeesChecked[data.item.id]"/>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.licenseType }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex
              v-if="showSkills"
              xs12
          >
            <v-autocomplete
                v-model="entry.skillIds"
                :items="skills"
                chips
                label="Skills"
                item-text="skill"
                item-value="id"
                multiple
                name="skills"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeSkill(data.item)"
                >
                  {{ data.item.skill }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex
              v-if="showEvaluationRequired"
              xs12
          >
            <v-checkbox
                v-model="entry.evaluationRequired"
                label="Require Course Evaluation Form, before hours are applied?"
            />
          </v-flex>
          <upload-files
            ref="uploadFiles"
            :file-list="entry.files"
            @deleteFile="deleteFile"
            @addNewFile="addNewFile"
          />
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
            text
            color="blue darken-1"
            @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            :loading="saving"
            @click="validateAndSave"
        >
          <template>Save</template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment-timezone';
import _ from 'lodash';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import ceHours from '@/api/ceHours';
import { convertNumberToTime, convertTimeToNumber } from '@/utils/convertNumberToTime';
import CustomTimePicker from '@/components/CustomTimePicker';
import UploadFiles from '@/components/CeEntry/UploadFiles';
import USER_ROLES from '@/enums/userRoles';

export default {
  name: 'EditEntryPopup',
  components: {
    CustomTimePicker,
    UploadFiles,
  },
  mixins: [errorAlertMixins],
  filters: {
    licenseType(item) {
      const map = {
        9925005: 'EMT',
      };
      if (map[item.licenseTypeId]) {
        return map[item.licenseTypeId];
      }
      return item.licenseType;
    },
  },
  props: {
    showAttendees: {
      type: Boolean,
      default: false,
    },
    showSkills: {
      type: Boolean,
      default: false,
    },
    showExternalCeEntryAlert: {
      type: Boolean,
      default: false,
    },
    canEditInstructorBasedType: {
      type: Boolean,
      default: false,
    },
    canEditEvaluationRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      saving: false,
      loading: false,
      isNew: true,
      showInstructorBasedType: false,
      showEvaluationRequired: false,
      entry: {
        id: null,
        date: null,
        provider: null,
        courseTitle: null,
        hours: null,
        instructorBasedType: 'instructor',
        evaluationRequired: false,
        userIds: [],
        skillIds: [],
        files: [],
      },
      entryAsyncFetch: {},
      time: null,
      timePickerMenu: null,
      datePicker: false,
      attendees: [],
      skills: [],
      providers: [],
      dictionary: {
        attributes: {
          date: 'Date',
          provider: 'Provider',
          courseTitle: 'Course Title',
          hours: 'Hours',
        },
        custom: {
          provider: {
            regex: () => 'Invalid format, the allowed format is XX-XXXX',
          },
        },
      },
      addedFiles: [],
      providerRegExp: new RegExp('^[0-9]{2}-[0-9]{4}$'),
      providerChanged: false,
      waitForProviderChangedComplete: false,
    };
  },

  computed: {
    /**
     * Formatted list of provider;
     *
     * @return {Boolean}
     */
    providersFormatted() {
      return this.providers.map((item) => {
        const provider = item;
        let providerName = provider.name;
        if (providerName && providerName.length > 60) {
          providerName = `${providerName.slice(0, 60)}...`;
        }
        provider.name = providerName
          ? `${provider.numberId} (${providerName})`
          : provider.numberId;
        return provider;
      });
    },

    /**
     * Format date to MM/DD/YYYY format.
     *
     * @return {Boolean}
     */
    dateFormatted() {
      return this.entry.date
        ? moment.parseZone(this.entry.date).format('MM/DD/YYYY')
        : null;
    },
    attendeesChecked() {
      const checked = {};
      this.attendees.forEach((item) => {
        checked[item.id] = this.entry.userIds.includes(item.id);
      });
      return checked;
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    providerKeyUpHandler() {
      this.providerChanged = true;
    },

    providerChangedHandler() {
      this.providerChanged = false;
      if (this.waitForProviderChangedComplete) {
        this.waitForProviderChangedComplete = false;
        this.validateAndSave();
      }
    },

    dateIsAllowed(value) {
      const now = moment.tz('America/Los_Angeles');
      const todayString = now.format('YYYY-MM-DD');
      const todayMoment = moment.parseZone(todayString);

      return !moment.parseZone(value).isAfter(todayMoment);
    },

    removeUser(item) {
      const index = this.entry.userIds.indexOf(item.id);
      if (index !== -1) {
        this.entry.userIds.splice(index, 1);
      }
    },

    removeSkill(item) {
      const index = this.entry.skillIds.indexOf(item.id);
      if (index !== -1) {
        this.entry.skillIds.splice(index, 1);
      }
    },

    /**
     * Close popup.
     */
    closeDialog() {
      this.addedFiles = [];
      this.dialog = false;
    },

    /**
     * Validate form and save if is valid.
     */
    async validateAndSave() {
      if (this.providerChanged) {
        this.waitForProviderChangedComplete = true;
        return;
      }
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }

      if (!this.isValidFilesCount()) {
        return;
      }

      this.saving = true;
      try {
        if (this.addedFiles.length !== 0) {
          await this.uploadFiles();
        }

        const entry = _.cloneDeep(this.entry);
        entry.hours = convertTimeToNumber(this.time);

        if (entry.id) {
          await ceHours.saveEntry(entry.id, entry);
        } else {
          await ceHours.createEntry(entry);
        }

        this.dialog = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response, true);
      } finally {
        this.saving = false;
      }
    },

    /**
     * Show dialog.
     */
    async show(entryId = null) {
      this.loading = true;
      this.dialog = true;
      this.time = null;
      this.entryAsyncFetch = {
        id: null,
        date: null,
        provider: null,
        courseTitle: null,
        hours: null,
        instructorBasedType: 'instructor',
        evaluationRequired: false,
        userIds: [],
        skillIds: [],
        files: [],
      };
      this.showInstructorBasedType = this.canEditInstructorBasedType;
      this.showEvaluationRequired = this.canEditEvaluationRequired;
      const promises = [
        this.fetchAllProviders(),
      ];
      if (entryId) {
        promises.push(this.fetchEntry(entryId));
        this.isNew = false;
      } else {
        this.isNew = true;
        if (this.canEditEvaluationRequired) {
          this.entryAsyncFetch.evaluationRequired = true;
        }
      }
      if (this.showAttendees) {
        promises.push(this.fetchAttendees());
        if (entryId) {
          promises.push(this.fetchEntryAttendees(entryId));
        }
      }
      if (this.showSkills) {
        promises.push(this.fetchAllSkills());
      }
      try {
        await Promise.all(promises);
        const entry = _.cloneDeep(this.entryAsyncFetch);
        this.entryAsyncFetch = null;
        this.entry = entry;
      } finally {
        this.loading = false;
      }
    },

    /**
     * Fetch list of all providers.
     */
    async fetchAllProviders() {
      let providers = await ceHours.getAllProviders();
      providers = providers.map(item => {
        const provider = item;
        const parts = item.numberId.split('-');
        const firstPart = parseInt(parts[0], 10);
        const secondPart = parts[1];
        provider.sort = parseFloat(`${firstPart}.${secondPart}`);
        return provider;
      });
      this.providers = _.sortBy(providers, ['sort']);
    },

    /**
     * Fetch list of all available skills.
     */
    async fetchAllSkills() {
      this.skills = await ceHours.getAllSkills();
    },

    /**
     * Fetch list of attendees attached to the entry.
     */
    async fetchEntryAttendees(entryId) {
      const ceEntryAttendees = await ceHours.getAttendees(entryId);
      ceEntryAttendees.forEach(item => {
        this.entryAsyncFetch.userIds.push(item.id);
      });
    },

    /**
     * Fetch list of all available attendees.
     */
    async fetchAttendees() {
      this.attendees = await ceHours.getAllAttendees();
    },

    /**
     * Fetch entry data.
     */
    async fetchEntry(entryId) {
      const entry = await ceHours.getEntry(entryId);
      this.entryAsyncFetch.id = entry.id;
      this.entryAsyncFetch.date = entry.date;
      this.entryAsyncFetch.provider = entry.provider;
      this.entryAsyncFetch.courseTitle = entry.courseTitle;
      this.entryAsyncFetch.hours = entry.hours;
      this.entryAsyncFetch.instructorBasedType = entry.instructorBasedType;
      this.entryAsyncFetch.files = entry.files;
      this.entryAsyncFetch.evaluationRequired = entry.evaluationRequired;
      const { skills } = entry;
      skills.forEach(item => {
        this.entryAsyncFetch.skillIds.push(item.id);
      });
      this.time = convertNumberToTime(entry.hours);
      this.showInstructorBasedType = this.canEditInstructorBasedType
          && entry.certificateIsAvailable;
      this.showEvaluationRequired = this.canEditEvaluationRequired && entry.evaluationIsAvailable;
    },

    /**
     * Delete file from list of entry files.
     *
     * @param file
     */
    deleteFile(file) {
      const index = this.entry.files.indexOf(file);

      if (index < 0) {
        return;
      }

      if (file.file === null) {
        const findFile = this.addedFiles.find(el => el.name === file.name);
        if (findFile !== undefined) {
          const indexFindFile = this.addedFiles.indexOf(findFile);
          this.addedFiles.splice(indexFindFile, 1);
        }
      }

      this.entry.files.splice(index, 1);
    },

    /**
     * Add new file.
     *
     * @param file
     */
    addNewFile(file) {
      const findFile = this.addedFiles.find(
        el => el.name === file.name && el.type === file.type && el.size === file.size,
      );

      if (findFile !== undefined) {
        return;
      }

      const newFile = { name: file.name, file: null };
      this.addedFiles.push(file);
      this.entry.files.push(newFile);
    },

    /**
     * Upload new added files.
     */
    async uploadFiles() {
      const uploadedFiles = await this.$refs.uploadFiles.uploadFiles(this.addedFiles);
      uploadedFiles.forEach((file) => {
        const entryFile = this.entry.files.find(el => el.id === undefined && el.name === file.name);
        const indexFile = this.entry.files.indexOf(entryFile);
        this.entry.files[indexFile].file = file.file;
      });
      this.addedFiles = [];
    },

    /**
     * Check count of entry files.
     *
     * @returns {boolean}
     */
    isValidFilesCount() {
      if ((this.entry.files.length) > 3) {
        this.showError('The number of files must be no more than three.');
        return false;
      }

      if (this.$store.state.user.userTypeId === USER_ROLES.CLIENT_ADMIN
          || (this.entry.files.length) >= 1
      ) {
        return true;
      }

      this.showError('The number of files must be at least one.');

      return false;
    },
  },
};
</script>
