<template>
  <div>
    <v-dialog
      v-model="showEditPopup"
      persistent
      class="client-edit"
      max-width="700"
      @close="$emit('close')"
    >
      <v-card>
        <v-card-title>
          {{ isNew ? $t('clients.editPopup.headerNew') : $t('clients.editPopup.headerEdit') }}
        </v-card-title>
        <v-card-text class="pt-0">
          <div
              v-show="loading"
              class="text-center"
          >
            <v-progress-circular
                :size="100"
                color="grey darken"
                center
                indeterminate
            />
          </div>
          <div v-show="!loading">
            <v-alert
                v-model="errorAlert"
                type="error"
                outlined
                dismissible
                transition="scale-transition"
                class="mb-4"
            >
              <div v-html="errorMessage" />
            </v-alert>
            <v-form>
              <v-layout wrap>
                <v-row class="mb-0">
                  <v-col cols="2">
                    <div
                        v-if="logoPreview || client.logo"
                        class="badge-button"
                    >
                      <v-btn
                          color="primary"
                          fab
                          small
                          dark
                          @click="deleteLogo"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </div>
                    <v-avatar
                        tile
                        size="50"
                        color="grey"
                    >
                      <img
                          v-if="logoPreview || client.logo"
                          :src="logoPreview || client.logoUrl"
                      >
                      <span
                          v-if="!client.logo && !logoPreview"
                          class="white--text headline"
                      >?</span>
                    </v-avatar>

                  </v-col>
                  <v-col
                      cols="6"
                      pb-3
                  >
                    <uploader
                        ref="uploader"
                        v-model="file"
                        :allowed-mime-types="mimeTypes"
                        :title="$t('clients.editPopup.uploadAvatar')"
                        :no-title-update="true"
                        fixed-width="100%"
                        @file-update="fileUpdated"
                    />
                    <span
                        v-for="(error, index) in errors.collect('tmpLogo')"
                        :key="index"
                        class="error--text"
                    >{{ error }}</span>
                  </v-col>
                </v-row>

                <v-col
                    cols="12"
                    class="pa-0"
                >
                  <span class="logo-recommendation">Recommended width: 150px</span>
                </v-col>

                <v-col
                    cols="12"
                    class="px-0 py-3"
                >
                  <v-spacer/>
                  <v-divider/>
                </v-col>

                <v-col
                    cols="6"
                    class="py-1"
                >
                  <v-text-field
                      key="clientName"
                      v-model="client.name"
                      v-validate="{ required: true }"
                      :label="$t('clients.editPopup.name')"
                      :error-messages="errors.collect('name')"
                      name="name"
                      maxlength="255"
                  />
                </v-col>

                <v-col
                    cols="6"
                    class="py-1"
                >
                  <v-select
                      key="clientType"
                      v-model="client.type"
                      v-validate="{ required: true }"
                      :items="clientTypes"
                      :label="$t('clients.editPopup.clientType')"
                      :error-messages="errors.collect('type')"
                      :menu-props="{'maxWidth': 300}"
                      :disabled="!isNew"
                      item-value="value"
                      item-text="text"
                      name="type"
                      @change="changeClientType"
                  />
                </v-col>
                <v-col
                    v-if="client.type === clientSiteTypes.DEFAULT"
                    cols="6"
                    class="py-1"
                >
                  <v-select
                      key="clientMapTypeId"
                      v-model="client.mapTypeId"
                      v-validate="{ required: true }"
                      :items="sortedProviders"
                      :label="$t('clients.editPopup.mapToType')"
                      :error-messages="errors.collect('mapTypeId')"
                      :menu-props="{'maxWidth': 300}"
                      item-value="value"
                      item-text="text"
                      name="mapTypeId"
                  />
                </v-col>
                <v-col
                    cols="6"
                    class="py-1"
                >
                  <v-text-field
                      key="clientDomain"
                      v-model="client.domain"
                      v-validate="{ required: true, regex: /^[a-zA-Z0-9]+$/ }"
                      :label="$t('clients.editPopup.url')"
                      :error-messages="errors.collect('domain')"
                      :hint="(client.domain || '?') + '.' + baseDomain"
                      name="domain"
                      persistent-hint
                  />
                </v-col>
                <v-col
                    v-if="client.type === clientSiteTypes.DEFAULT"
                    cols="6"
                    class="py-1"
                >
                  <v-select
                      key="clientTemplateId"
                      v-model="client.templateId"
                      v-validate="{ required: true }"
                      :items="entity.templates"
                      :error-messages="errors.collect('templateId')"
                      :label="$t('clients.editPopup.template')"
                      item-value="templateId"
                      item-text="name"
                      name="templateId"
                  />
                </v-col>
                <v-col
                    v-if="client.type === clientSiteTypes.DEFAULT"
                    cols="6"
                    class="py-1"
                >
                  <v-autocomplete
                      v-model="client.nemsisAgencyCode"
                      :items="nemsisAgencyItems"
                      :label="$t('clients.editPopup.nemsisMapping')"
                      item-value="value"
                      item-text="text"
                      name="nemsisAgencyCode"
                      clearable
                  />
                </v-col>
                <v-col
                    v-if="client.type === clientSiteTypes.DEFAULT"
                    cols="6"
                    class="py-1"
                >
                  <v-checkbox
                      v-model="client.faxEnabled"
                      :label="$t('clients.editPopup.faxEnabled')"
                  />
                </v-col>
                <v-col
                    v-if="client.type === clientSiteTypes.DEFAULT"
                    cols="6"
                    class="py-1"
                >
                  <v-checkbox
                      v-model="client.BLSAllowed"
                      :label="$t('clients.editPopup.blsAllowed')"
                  />
                </v-col>
                <v-col
                    v-if="client.type === clientSiteTypes.DEFAULT"
                    cols="6"
                    class="py-1"
                >
                  <v-checkbox
                      v-model="client.ceEnabled"
                      :label="$t('clients.editPopup.ceEnabled')"
                  />
                </v-col>
                <v-col
                    v-if="client.type === clientSiteTypes.DEFAULT"
                    cols="12"
                    class="py-1"
                >
                  <v-menu
                      ref="datePicker"
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="passwordExpiresAtFormatted"
                          :label="$t('clients.editPopup.passwordExpiresAt')"
                          readonly
                          hint="own passwords are allowed only starting from"
                          persistent-hint
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="client.passwordExpiresAt"
                        no-title
                        @input="datePicker = false"
                    >
                      <v-spacer/>
                      <v-btn
                          text
                          color="primary"
                          @click="passwordExpiresAtClear"
                      >
                        Clear
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  class="py-1"
                >
                  <v-text-field
                      key="clientAddress"
                      v-model="client.address"
                      v-validate="{ required: true }"
                      :label="$t('clients.editPopup.address')"
                      :error-messages="errors.collect('address')"
                      name="address"
                      maxlength="255"
                  />
                </v-col>

                <v-col
                    cols="6"
                    class="py-1"
                >
                  <v-text-field
                      key="clientCity"
                      v-model="client.city"
                      v-validate="{ required: true }"
                      :label="$t('clients.editPopup.city')"
                      :error-messages="errors.collect('city')"
                      name="city"
                      maxlength="255"
                  />
                </v-col>
                <v-col
                    cols="3"
                    class="py-1"
                >
                  <v-select
                      key="clientStateId"
                      v-model="client.stateId"
                      v-validate="{ required: true }"
                      :items="states"
                      :label="$t('clients.editPopup.state')"
                      :error-messages="errors.collect('state')"
                      name="state"
                  />
                </v-col>
                <v-col
                    cols="3"
                    class="py-1"
                >
                  <v-text-field
                      key="clientZip"
                      v-model="client.zip"
                      v-validate="{ required: true }"
                      :label="$t('clients.editPopup.zip')"
                      :error-messages="errors.collect('zip')"
                      name="zip"
                      maxlength="5"
                  />
                </v-col>
                <v-col
                    cols="12"
                    class="py-1"
                >
                  <v-textarea
                      v-model="client.comments"
                      :label="$t('clients.editPopup.comments')"
                      rows="2"
                      auto-grow
                  />
                </v-col>
                <v-col
                    v-if="isNew"
                    cols="12"
                    class="py-1"
                >
                  <v-text-field
                      key="clientAdminEmail"
                      v-model="client.admin.email"
                      v-validate="{ required: isNew, email: true }"
                      :label="$t('clients.editPopup.adminEmail')"
                      :error-messages="errors.collect('email')"
                      name="email"
                  />
                </v-col>
                <v-col
                    v-if="isNew"
                    cols="6"
                    class="py-1"
                >
                  <v-text-field
                      key="clientAdminPassword"
                      ref="password"
                      v-model="client.admin.password"
                      v-validate="{ required: true, regex: validatePasswordRegexp }"
                      :error-messages="errors.collect('password')"
                      :label="$t('clients.editPopup.adminPassword')"
                      name="password"
                      type="password"
                      append-icon="lock"
                      autocomplete="new-password"
                  />
                </v-col>
                <v-col
                    v-if="isNew"
                    cols="6"
                    class="py-1"
                >
                  <v-text-field
                      key="clientAdminRetypePassword"
                      v-model="client.admin.retypePassword"
                      v-validate="{ required: isNew, confirmed: 'password' }"
                      :error-messages="errors.collect('retypePassword')"
                      :label="$t('clients.editPopup.retypePassword')"
                      type="password"
                      name="retypePassword"
                      data-vv-as="password"
                      append-icon="lock"
                  />
                </v-col>
                <v-col
                    v-if="isNew"
                    cols="12"
                    class="mb-3"
                >
                  <i>{{ $t('general.passwordFormat') }}</i>
                </v-col>
                <v-expansion-panels
                    v-if="client.type === clientSiteTypes.DEFAULT"
                    v-model="credentialsPanel"
                    focusable
                    flat
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{ $t('clients.editPopup.billingFtpCredentials') }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-5">
                      <v-flex xs12>
                        <b>{{ $t('clients.editPopup.billingServer') }}:</b>
                        billftp.{{ baseDomain }}:2224
                      </v-flex>
                      <v-flex xs12>
                        <b>{{ $t('clients.editPopup.billingUser') }}:</b> {{ client.domain }}
                      </v-flex>
                      <v-flex
                          v-if="billingPasswordEditMode"
                          xs12
                      >
                        <v-text-field
                            v-model="billingFtpUser.passwd"
                            :label="$t('clients.editPopup.billingPassword')"
                            :hint="$t('clients.editPopup.emptyPassword')"
                            type="password"
                            append-icon="lock"
                            persistent-hint
                            @change="billingPasswordIsChanged = true"
                        />
                      </v-flex>
                      <v-flex
                          v-if="!billingPasswordEditMode"
                          xs12
                      >
                        <b>{{ $t('clients.editPopup.billingPassword') }}:</b> ******
                        <span class="edit-password-button">
                        <v-btn
                            color="primary"
                            fab
                            small
                            dark
                            @click="changeBillingPassword"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </span>
                      </v-flex>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                          {{ $t('clients.editPopup.odMapCredentials') }}
                          <v-icon
                              v-if="credentialsPanel !== 1
                              && odMapEditMode
                              && (
                                  errors.collect('odMapLogin').length > 0
                                  || errors.collect('odMapPassword').length > 0
                              )"
                              color="orange"
                              class="ml-3"
                          >
                          mdi-alert
                        </v-icon>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-5">
                      <v-flex
                          v-if="!odMapEditMode"
                          xs12
                      >
                        <b>{{ $t('clients.editPopup.odMapLogin') }}:</b> {{ client.odMapLogin }}
                      </v-flex>
                      <v-flex
                          v-if="!odMapEditMode"
                          xs12
                      >
                        <b>{{ $t('clients.editPopup.odMapPassword') }}:</b> ******
                        <span class="edit-password-button">
                            <v-btn
                                color="primary"
                                fab
                                small
                                dark
                                @click="changeOdMap"
                            >
                              <v-icon>edit</v-icon>
                            </v-btn>
                          </span>
                      </v-flex>

                      <v-flex
                          v-if="odMapEditMode"
                          xs12
                      >
                        <v-text-field
                            v-model="client.odMapLogin"
                            v-validate="{ required: !!client.odMapPassword, email: true }"
                            :label="$t('clients.editPopup.odMapLogin')"
                            :error-messages="errors.collect('odMapLogin')"
                            name="odMapLogin"
                        />
                      </v-flex>
                      <v-flex
                          v-if="odMapEditMode"
                          xs12
                      >
                        <v-text-field
                            ref="odMapPassword"
                            v-model="client.odMapPassword"
                            v-validate="{ required: !!client.odMapLogin }"
                            :error-messages="errors.collect('odMapPassword')"
                            :label="$t('clients.editPopup.odMapPassword')"
                            name="odMapPassword"
                            type="password"
                            append-icon="lock"
                            autocomplete="new-password"
                        />
                      </v-flex>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                          {{ $t('clients.editPopup.zollCloudCredentials') }}
                          <v-icon
                              v-if="credentialsPanel !== 2
                              && zollCredsEditMode
                              && (
                                  errors.collect('zollCloudDak').length > 0
                                  || errors.collect('zollCloudPassword').length > 0
                              )"
                              color="orange"
                              class="ml-3"
                          >
                          mdi-alert
                        </v-icon>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-5">
                      <v-flex
                          v-if="!zollCredsEditMode"
                          xs12
                      >
                        <b>
                          {{ $t('clients.editPopup.zollCloudDak') }}:
                        </b> {{ client.zollCloudDak }}
                      </v-flex>
                      <v-flex
                          v-if="!zollCredsEditMode"
                          xs12
                      >
                        <b>{{ $t('clients.editPopup.zollCloudPassword') }}:</b> ******
                        <span class="edit-password-button">
                            <v-btn
                                color="primary"
                                fab
                                small
                                dark
                                @click="changeZollCreds"
                            >
                              <v-icon>edit</v-icon>
                            </v-btn>
                          </span>
                      </v-flex>

                      <v-flex
                          v-if="zollCredsEditMode"
                          xs12
                      >
                        <v-text-field
                            v-model="client.zollCloudDak"
                            v-validate="{ required: !!client.zollCloudPassword }"
                            :label="$t('clients.editPopup.zollCloudDak')"
                            :error-messages="errors.collect('zollCloudDak')"
                            name="zollCloudDak"
                        />
                      </v-flex>
                      <v-flex
                          v-if="zollCredsEditMode"
                          xs12
                      >
                        <v-text-field
                            ref="zollCloudPassword"
                            v-model="client.zollCloudPassword"
                            v-validate="{ required: !!client.zollCloudDak }"
                            :error-messages="errors.collect('zollCloudPassword')"
                            :label="$t('clients.editPopup.zollCloudPassword')"
                            name="zollCloudPassword"
                            type="password"
                            append-icon="lock"
                            autocomplete="new-password"
                        />
                      </v-flex>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span>
                        {{ $t('clients.editPopup.lifeNetCredentials') }}
                        <v-icon
                            v-if="credentialsPanel !== 3
                             && errors.collect('lifeNetAccountId').length > 0"
                            color="orange"
                            class="ml-3"
                        >
                          mdi-alert
                        </v-icon>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-5">
                      <v-flex xs12>
                        <v-text-field
                          v-model="client.lifeNetAccountId"
                          v-validate="{ min: 36, max: 36,  regex: lifeNetAccountRegexp}"
                          :label="$t('clients.editPopup.lifeNetAccountId')"
                          :error-messages="errors.collect('lifeNetAccountId')"
                          name="lifeNetAccountId"
                        />
                      </v-flex>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

              </v-layout>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer/>
          <v-btn
              v-if="!isNew"
              :disabled="savingClient"
              color="blue darken-1"
              text
              @click.native="$emit('clickDelete', client)"
          >{{ $t('buttons.delete') }}</v-btn>
          <v-btn
              v-if="!isNew"
              :disabled="savingClient"
              color="blue darken-1"
              text
              @click.native="impersonate(client.registryId)"
          >{{ $t('clients.editPopup.impersonate') }}</v-btn>
          <v-btn
              :disabled="savingClient"
              color="blue darken-1"
              text
              @click.native="hidePopup()"
          >{{ $t('buttons.cancel') }}</v-btn>
          <v-btn
              :loading="savingClient"
              color="primary"
              text
              @click.native="validateAndSave()"
          >{{ $t('buttons.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation ref="confirmationPopup"/>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, createNamespacedHelpers } from 'vuex';
import Uploader from '@/components/Uploader';
import Confirmation from '@/components/Confirmation';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import impersonateMixins from '@/mixins/impersonateMixins';
import validatePasswordRegexp from '@/data/Misk/validatePasswordRegexp';
import { LOAD_STATES } from '@/store/LookupStates/actions';
import { LOAD_PROVIDERS, LOAD_TEMPLATES, LOAD_NEMSIS_AGENCIES } from '@/store/Clients/actions';
import apiClients from '@/api/clients';
import apiBillingFtpUsers from '@/api/billingFtpUsers';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';

const { mapState, mapGetters } = createNamespacedHelpers('clients');

export default {
  name: 'ClientEditPopup',

  components: {
    Uploader,
    Confirmation,
  },
  mixins: [errorAlertMixins, impersonateMixins],

  data() {
    return {
      clientSiteTypes: CLIENT_SITE_TYPES,
      isNew: false,
      credentialsPanel: null,
      client: {},
      billingFtpUser: {},
      billingPasswordIsChanged: false,
      billingPasswordEditMode: false,
      odMapEditMode: false,
      lifeNetAccountEditMode: false,
      zollCredsEditMode: false,
      showEditPopup: false,
      loading: false,
      savingClient: false,
      datePicker: false,
      mimeTypes: ['image/jpeg', 'image/png'],
      file: null,
      logoPreview: null,
      validatePasswordRegexp,
      baseDomain: process.env.VUE_APP_BASE_DOMAIN,
      dictionary: {
        attributes: {
          name: this.$t('clients.editPopup.name'),
          mapTypeId: this.$t('clients.editPopup.mapToType'),
          domain: this.$t('clients.editPopup.url'),
          templateId: this.$t('clients.editPopup.template'),
          odMapLogin: this.$t('clients.editPopup.odMapLogin'),
          odMapPassword: this.$t('clients.editPopup.odMapPassword'),
          lifeNetAccountId: this.$t('clients.editPopup.lifeNetAccountId'),
        },
      },
      clientTypes: [
        {
          value: CLIENT_SITE_TYPES.DEFAULT,
          text: 'Default',
        },
        {
          value: CLIENT_SITE_TYPES.CE_ENTRY,
          text: 'Continuing Education',
        },
      ],
      lifeNetAccountRegexp: new RegExp('^[0-9A-Fa-f-]{36}$'),
    };
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),

    ...mapGetters(['sortedProviders', 'nemsisAgencyItems']),

    /**
     * Format release date to MM/DD/YYYY format.
     *
     * @return {Boolean}
     */
    passwordExpiresAtFormatted() {
      return this.client.passwordExpiresAt
        ? moment.parseZone(this.client.passwordExpiresAt).format('MM/DD/YYYY')
        : null;
    },
    states() {
      return this.$store.state.lookupStates.states.map(state => state.stateID);
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
    this.loadStates();
  },

  methods: {
    ...mapActions({
      loadStates: `lookupStates/${LOAD_STATES}`,
      loadProviders: `clients/${LOAD_PROVIDERS}`,
      loadTemplates: `clients/${LOAD_TEMPLATES}`,
      loadNemsisAgencies: `clients/${LOAD_NEMSIS_AGENCIES}`,
    }),

    /**
     * Save or create client.
     *
     * @return {Promise}
     */
    saveClient() {
      const clientData = _.cloneDeep(this.client);
      if (clientData.type === CLIENT_SITE_TYPES.CE_ENTRY) {
        clientData.mapTypeId = null;
        clientData.templateId = null;
        clientData.nemsisAgencyCode = null;
        clientData.faxEnabled = false;
        clientData.BLSAllowed = false;
        clientData.passwordExpiresAt = null;
        clientData.billingFtpUser = null;
        clientData.odMapLogin = null;
        clientData.lifeNetAccountId = null;
        clientData.odMapPassword = null;
        clientData.zollCloudDak = null;
        clientData.zollCloudPassword = null;
        clientData.ceEnabled = true;
      }

      if (this.client.registryId) {
        return apiClients.saveClient(clientData);
      }
      return apiClients.createClient(clientData);
    },

    /**
     * Save or create or delete billing ftp user.
     *
     * @return {Promise}
     */
    async saveBillingFtpUser() {
      if (!this.billingFtpUser.passwd && this.client.billingFtpUser) {
        await apiBillingFtpUsers.deleteFtpUser(this.billingFtpUser.clientId);
      } else if (this.billingFtpUser.passwd && !this.client.billingFtpUser) {
        await apiBillingFtpUsers.createFtpUser(this.billingFtpUser);
      } else if (this.billingFtpUser.passwd && this.client.billingFtpUser) {
        await apiBillingFtpUsers.saveFtpUser(this.billingFtpUser);
      }
    },

    /**
     * Save or create client.
     */
    async validateAndSave() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.savingClient = true;
      try {
        if (this.file) {
          const presignedData = await this.$refs.uploader.upload();
          this.client.tmpLogo = presignedData.tmpFilename;
        }
        const client = await this.saveClient();
        if (this.billingPasswordIsChanged) {
          this.billingFtpUser.clientId = client.registryId;
          this.billingFtpUser.userid = client.domain;
          await this.saveBillingFtpUser();
        }
        this.showEditPopup = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.savingClient = false;
      }
    },

    /**
     * Show new edit popup.
     *
     * @param {Boolean} isNew - Flag determines client is new or edit existence
     * @param {Object} client - Client object
     * @param {Object} billingFtpUser - Billing ftp user object
     */
    async showPopup(isNew, client, billingFtpUser) {
      await this.$validator.reset();
      this.$validator.pause();
      this.errors.clear();
      this.hideError();
      this.isNew = isNew;
      this.credentialsPanel = null;
      this.client = client;
      this.billingFtpUser = billingFtpUser;
      this.billingPasswordIsChanged = false;
      this.showEditPopup = true;
      this.loading = true;
      this.file = null;
      this.logoPreview = null;
      try {
        await this.loadData();
      } finally {
        this.loading = false;
      }
      this.$refs.uploader.clear();
      this.$validator.resume();
    },

    /**
     * Hide edit client popup.
     */
    hidePopup() {
      this.showEditPopup = false;
    },

    /**
     * Show new client popup.
     */
    showNew() {
      this.billingPasswordEditMode = true;
      this.odMapEditMode = true;
      this.zollCredsEditMode = true;
      this.lifeNetAccountEditMode = true;
      this.showPopup(
        true,
        {
          name: null,
          type: CLIENT_SITE_TYPES.DEFAULT,
          mapTypeId: null,
          templateId: null,
          faxEnabled: false,
          BLSAllowed: false,
          domain: null,
          admin: {
            password: null,
            retypePassword: null,
            email: null,
          },
          billingFtpUser: null,
          odMapLogin: null,
          odMapPassword: null,
          zollCloudDak: null,
          zollCloudPassword: null,
          ceEnabled: false,
          lifeNetAccountId: null,
        },
        {},
      );
    },

    /**
     * Show edit client popup.
     *
     * @param {Object} client - Client object.
     */
    async showEdit(client) {
      const billingFtpUser = client.billingFtpUser || {};
      this.billingPasswordEditMode = !client.billingFtpUser;
      this.odMapEditMode = !client.odMapLogin;
      this.zollCredsEditMode = !client.zollCloudDak;
      this.lifeNetAccountEditMode = !client.lifeNetAccountId;
      this.showPopup(false, client, billingFtpUser);
    },

    async loadData() {
      const promises = [this.loadProviders(), this.loadTemplates(), this.loadNemsisAgencies()];
      return Promise.all(promises);
    },

    /**
     * Clear password expires at field.
     */
    passwordExpiresAtClear() {
      this.client.passwordExpiresAt = null;
      this.datePicker = false;
    },

    /**
     * Handler when file is selected.
     *
     * @param {Object} file - Selected file object
     */
    fileUpdated(file) {
      if (!file) {
        return;
      }
      const reader = new FileReader();
      const that = this;
      /* eslint func-names:0 */
      reader.onload = function (event) {
        that.logoPreview = event.target.result;
      };
      reader.readAsDataURL(file);
    },

    /**
     * Delete logo.
     */
    deleteLogo() {
      this.client.logo = null;
      this.file = null;
      this.logoPreview = null;
      this.$refs.uploader.clear();
    },

    /**
     * Show change billing ftp password confirmation popup.
     */
    changeBillingPassword() {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('clients.changePasswordConfirmationTitle'),
        this.$t('clients.changePasswordConfirmationText'),
        () => {
          this.billingPasswordIsChanged = true;
          this.billingPasswordEditMode = true;
          this.billingFtpUser.passwd = null;
        },
      );
    },

    /**
     * Show change OD Map confirmation popup.
     */
    changeOdMap() {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('clients.changeOdMapConfirmationTitle'),
        this.$t('clients.changeOdMapConfirmationText'),
        () => {
          this.odMapEditMode = true;
          this.client.odMapPassword = null;
        },
      );
    },

    /**
     * Show change ZOLL Credentials confirmation popup.
     */
    changeZollCreds() {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('clients.changeZollCredsConfirmationTitle'),
        this.$t('clients.changeZollCredsConfirmationText'),
        () => {
          this.zollCredsEditMode = true;
          this.client.zollCloudPassword = null;
        },
      );
    },

    async changeClientType() {
      this.$validator.reset();
    },
  },
};
</script>

<style lang="scss">
.badge-button {
  position: relative;
  top: -10px;
  right: -35px;
  height: 0;
  z-index: 1;
}

.badge-button .v-btn {
  width: 25px;
  height: 25px;
  margin: 0;
}

.edit-password-button .v-btn {
  width: 30px;
  height: 30px;
  margin: 0 0 10px 10px;
}

.logo-recommendation {
  font-size: 10px;
  color: grey;
}

</style>

<style lang="scss" module>
.lifeNetApiKey {
  display: inline-block;
  max-width: 375px;
}
.lifeNetApiKey input {
  width: 335px;
}
.lifeNetApiKeyButton {
  display: inline-block;
  margin-left: 10px;
}
.lifeNetNoApiKey {
  display: inline-block;
  width: 440px;
}
</style>
